import MSaga from "../../helper/MSaga";
import { progressActions } from "./progressReducer";

const progressSaga = new MSaga({
    name: "progress",
    apiEndPoint: "",
    actions: progressActions,
    messages: {
        updateOne: {
            success: "progress Updated",
            error: "",
        },
    },
});
export default progressSaga.getWatchSaga();
