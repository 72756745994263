const routes = {
  ROOT: "/",

  // Authentication routes start
  LOGIN: "/login",
  FREELANCER_LOGIN: "/creator",
  FREELANCER_SIGNUP: "/signup/creator",
  // Authentication routes end
  CLIENT_LOGIN: "/requestor",
  CLIENT_SIGNUP: "/signup/requestor",

  //Signup routes start
  SIGNUP: "/signup",
  // Signup routes end

  //Signup routes start
  FORGOTPASSWORD: "/forgotpassword",
  // Signup routes end

  //Email-verify routes start
  EMAIL_VERIFY: "/account-activate",
  // Email-verify routes end

  //Password reset routes start
  RESET_PASSWORD: "/reset-password",
  //Password reset routes end

  // app routes start
  APP: "/app",

  // dashboard routes start
  DASHBOARD: "/app/dashboard",
  // dashboard routes end

  // task routes start

  TASK: "/app/task",
  TASK_ID: "/app/task/:id",

  PROJECT_TASK: "/app/project-task",
  PROJECT_TASK_DELAYED: "/app/project-task-delayed",

  USERS: "/app/users",

  // task routes end
  FREELANCER: "/app/freelancers",
  FREELANCER_ID: "/app/freelancers/:id",
  // projects routes start

  // project list route
  PROJECT: "/app/project",

  // project details route
  PROJECT_DETAIL: "/app/project/:id",

  // create project route
  CREATE_PROJECT_FLOW: "/app/create-project-flow",

  // project draft list route
  PROJECT_DRAFT: "/app/project-draft",
  // project drafts routes end

  // project archived list route
  PROJECT_ARCHIVED: "/app/project-archived",
  // project archived routes end

  // project task/assignment details route
  PROJECT_TASK_DETAIL: "/app/project/:projectId/task/:id",

  // projects routes end

  // user profile routes start
  PROFILE: "/app/profile",
  // user profile routes end

  // invoicing start
  INVOICE_WRITER: "/app/invoice/writer",
  INVOICE_REQUESTOR: "/app/invoice/requestor",
  // invoicing end

  // role management routes
  ROLES: "/app/roles",
  ROLE_DETAILS: "/app/role/:id",

  // app routes end

  // user profile routes start
  LOGOUT: "/logout",
  // user profile routes end

  // form builder start
  FORM_BUILDER: "/app/form-builder",
  // form builder end

  // Assessment QNA start
  ASSESSMENTS: "/app/assessments",
  ASSESSMENT_DETAILS: "/app/assessment",
  ASSESSMENT_TEST: "/app/assessmentTest/:id",
  TEST: "/app/test/:id",
  // Assessment QNA end

  // template
  TEMPLATE: "/app/template",
  TEMPLATE_DETAIL: "/app/template/:id",
  // template end

  // notifications start
  NOTIFICATION: "/app/notifications",
  // notifications end

  // writely-bot
  OPEN_AI: "/app/writely-bot",
  OPEN_AI_DASHBOARD: "/app/writely-bot/dashboard",
  OPEN_AI_TEMPLATES: "/app/writely-bot/templates",
  OPEN_AI_TASKS: "/app/writely-bot/tasks",
  OPEN_AI_SEO_META_TAG: "/app/writely-bot/seo-meta-tag",
  OPEN_AI_SOCIAL_BOOKMARKING: "/app/writely-bot/social-bookmarking",
  OPEN_AI_BLOG: "/app/writely-bot/blog",
  OPEN_AI_QUORA_QNA: "/app/writely-bot/quora-qna",
  OPEN_AI_CHAT: "/app/writely-bot/chat",
  OPEN_AI_SOCIAL_MEDIA_COPY: "/app/writely-bot/social-media-copy",
  OPEN_AI_PAID_AD_COPY: "/app/writely-bot/paid-ad-copy",
  OPEN_AI_KEYWORD_GENERATOR: "/app/writely-bot/keyword-generator",
  OPEN_AI_TRANSLATOR: "/app/writely-bot/translator",
  OPEN_AI_BLOG_OUTLINE: "/app/writely-bot/blog-outline",
  OPEN_AI_BLOG_NEW: "/app/writely-bot/blog-new",
  OPEN_AI_LINKEDIN_PAID: "/app/writely-bot/linkedin-paid-ad-copy",
  OPEN_AI_INSTAGRAM_PAID: "/app/writely-bot/instagram-paid-ad-copy",
  OPEN_AI_TWITTER_PAID: "/app/writely-bot/twitter-paid-ad-copy",

  // bulk content creation
  BULK_CONTENT_CREATION: "/app/bulk-content-creation",

  CONTENT_DETAIL: "/app/bulk-content-creation/:id",

  CONTENT_TASK_DETAIL: "/app/bulk-content-creation/:projectId/task/:id",

  TESTING: "/app/bulk-content-creation/task/:id",

  GENERATE_REPORTS: "/app/bulk-content-creation/generate-report/",
};

Object.freeze(routes);
export default routes;
