import React from "react";
import { Layout, Menu, Row } from "antd";
import { useLocation } from "react-router-dom";
import appLargeLogo from "../assets/images/logoWhite.svg";
// import appSmallLogo from "../assets/images/W.svg";
import newLogoCollapsed from "../assets/images/newLogoCollapsed.svg";
import useSidebarMenuItems from "./useSidebarMenuItems";

import "./AppSidebar.scss";

const AppSidebar = (props) => {
  const location = useLocation();
  const appMenuItems = useSidebarMenuItems();
  // console.log(props?.isSidebarCollapsed);

  console.log(appMenuItems, "appMenuItems");
  return (
    <Layout.Sider
      className="app-sidebar"
      width={240}
      trigger={null}
      collapsible
      collapsed={props?.isSidebarCollapsed}
      theme="dark"
      breakpoint="md"
      onBreakpoint={(broken) => {
        if (broken && !props?.isSidebarCollapsed) props?.toggleSidebar();
      }}
    >
      <Row className="logo-container">
        {!props?.isSidebarCollapsed ? (
          <div className="logo">
            {/* <img alt="" src={newLogoCollapsed} /> */}
            <img alt="" src={appLargeLogo} />
          </div>
        ) : (
          // <img className="logo" alt="" src={appSmallLogo} />
          <img className="logo" alt="" src={newLogoCollapsed} />
        )}
      </Row>

      <Menu
        theme="dark"
        mode="inline"
        className="app-sidebar-menu"
        selectedKeys={[`${location?.pathname}`]}
        items={appMenuItems}
      />
    </Layout.Sider>
  );
};

export default React.memo(AppSidebar);
