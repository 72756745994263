import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Layout, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";

import { loggedInUserActions } from "../loggedInUser/duck/loggedInUserReducer";

// import {
//   getIsSidebarCollapsedFromStore,
//   setIsSidebarCollapsedFromStore,
// } from "../utils/commonFunctions";
// import constants from "../utils/constants";
// import routes from "../utils/routes";
import Loading from "./Loading";
// import TermsAndConditionsModal from "./TermsAndConditionsModal";
import useSidebarMenuItems from "./useSidebarMenuItems";
import { initializeSocket } from "../config/Socket";
import {
  commentSocket,
  itemSocket,
  projectSocket,
  roleSocket,
  userSocket,
  notificationSocket,
  assessmentSocket,
  progressSocket
} from "../utils/SocketApi";

// import AppFooter from "./AppFooter";

const AppLayoutContainer = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser);
  const mUser = useMemo(() => loggedInUser.data, [loggedInUser.data]);

  const sidebarMenuItems = useSidebarMenuItems();

  // const [showModal, handleModal] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    true
    // getIsSidebarCollapsedFromStore()
  );

  const toggleSidebar = useCallback(
    (bool = null) => {
      // setIsSidebarCollapsed((state) => {
      //   const value = typeof bool === "boolean" ? bool : !state;
      //   setIsSidebarCollapsedFromStore(value);
      //   return value;
      // });
      setIsSidebarCollapsed(!isSidebarCollapsed);
    },
    [isSidebarCollapsed]
  );

  useEffect(() => {
    dispatch(loggedInUserActions.onGetUserDetailsRequest());
    initializeSocket();
  }, [dispatch]);

  useEffect(() => {
    if (mUser) {
      itemSocket();
      projectSocket();
      userSocket();
      commentSocket();
      roleSocket();
      assessmentSocket();
      notificationSocket();
      progressSocket();
    }
  }, [mUser]);

  // useEffect(() => {
  //   if (mUser && typeof mUser?.consent === "boolean")
  //     handleModal(!mUser?.consent);
  // }, [mUser, mUser?.consent]);

  if (!mUser || (!mUser && loggedInUser.loading)) {
    return <Loading />;
  }

  if (location.state?.autoNavigate && sidebarMenuItems.length > 0) {
    const navigateTo =
      sidebarMenuItems[0].key || sidebarMenuItems[0]?.children?.[0]?.key;
    return <Navigate to={navigateTo} replace />;
  }

  return (
    <Layout className="fh --main-container">
      <AppSidebar
        toggleSidebar={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
      />

      <Layout>
        <AppHeader
          collapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />
        {/* {mUser?.role_id === "freelancer" && showModal && (
          <TermsAndConditionsModal
            loggedInUser={loggedInUser}
            showModal={showModal}
            handleModal={handleModal}
          />
        )} */}
        <Layout.Content>{props.children}</Layout.Content>
        {/* <AppFooter /> */}
      </Layout>
    </Layout>
  );
};

export default AppLayoutContainer;
