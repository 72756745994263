import { Col, Row } from "antd";
import React from "react";
import NewTickImage from "../../assets/images/tick_new.svg";
import TickImage from "../../assets/images/tick.svg";
import ClipBoardImg from "../../assets/images/clipboard.svg";
import {abbreviateNumber} from "../../utils/commonFunctions";

const ContentTaskCard = ({data}) => {
  let {totalItems, completedItems, inProgressItems} = data;
  return (
    <div>
      {" "}
      <Row className="items-container" gutter={[15, 15]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div className="card pri-box-shadow">
            <Row justify="space-between" wrap={false}>
              <Col>
                <p>
                   {abbreviateNumber(Number(totalItems || 0), 1)}
                </p>
                <span className="whitespace-nowrap">Total Task</span>
              </Col>

              <img width={35} src={ClipBoardImg} alt="" />
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div className="card pri-box-shadow">
            <Row justify="space-between" wrap={false}>
              <Col>
                <p>
                   {abbreviateNumber(Number(inProgressItems || 0), 1)}
                </p>
                <span className="whitespace-nowrap">In Progress</span>
              </Col>

              <img src={NewTickImage} alt="" />
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div className="card pri-box-shadow">
            <Row justify="space-between" wrap={false}>
              <Col>
                <p>
                   {abbreviateNumber(Number(completedItems || 0), 1)}
                </p>
                <span className="whitespace-nowrap">Closed</span>
              </Col>

              <img width={35} src={TickImage} alt="" />
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContentTaskCard;
