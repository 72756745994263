import React, {useEffect, useState} from "react";
import {CheckOutlined, CloseOutlined, InboxOutlined} from "@ant-design/icons";
import { message, Upload, Modal, Button, Progress } from "antd";
import "./CsvUploadModal.scss";
import AppUrl from "../../config/appUrl";
import {useDispatch, useSelector} from "react-redux";
import {itemActions} from "../../item/duck/itemReducer";
import {showNotification} from "../../utils/commonFunctions";
import classNames from "classnames";
import {socket} from "../../config/Socket";

const { Dragger } = Upload;


const CsvUploadModal = ({ isVisible, onClose, activeProject }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({progress: 0})
  const progressObj = useSelector(({ progress }) => progress.map["12345"]);
  console.log("-------->",progressObj)
  const steps = [
    { id: 1, description: "File successfully uploaded", status: "success" }, // blue background
    { id: 2, description: "File processing initiated", status: "initiating" }, // gray background
    { id: 2, description: "File processing completed", status: "completed" }, // gray background
  ];
  useEffect(() => {
    setState({progress: Number(progressObj?.percent)})
  }, [progressObj]);

  const props = {
    name: "file",
    multiple: false,
    action: AppUrl.BULK_AI_PROJECT,
    headers: {Authorization: `Bearer ${localStorage.getItem("accessToken")}`},
    data: {project_id: activeProject?._id, current_assignee: activeProject?.assigned_editor?._id},
    crossOrigin: "use-credentials",
    onChange(info) {
      const { status } = info.file;
      if(status === "uploading"){
        setLoading(true)
        setState({progress: 5})
        setTimeout(()=>setState({progress: 10}), 5000)
      }

      if (status === "done") {
        const params = {
          project_id: activeProject?._id,
          status_description: "inreview",
          offset: 0,
          limit: 30,
        };
        dispatch(itemActions.onGetAllRequest(params));
        // setTimeout(()=>{
        //   setLoading(false)
        //   onClose()
        // }, 5000)
        showNotification("success", "Successfully Uploaded file");
        // message.success(`${info.file.name} file uploaded successfully.`);

      } else if (status === "error") {
        setLoading(false)
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Modal
      title="Upload CSV Files"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button disabled={loading} key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button disabled={loading} key="ok" type="primary" onClick={onClose}>
          OK
        </Button>,
      ]}
    >
      <Dragger disabled={loading} {...props}>
        {!loading && (<><p className="ant-upload-drag-icon">
          <InboxOutlined/>
        </p>
          <p className="ant-upload-text">
          Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from
          uploading company data or other banned files.
          </p></>)}
        {loading && (<>
        {/*<div className="timer-icon">*/}
        {/*  <span className="time-text">2:04 min</span>*/}
        {/*</div>*/}
        <div>
          <ul className="custom-list">
            <div className={"mb-3"}>
              <Progress  type="circle" percent={state?.progress} />
            </div>

            {steps.map((step) => (
                <li
                    key={step.id}
                    className={classNames("indicator-container", {
                      "blue-bg": step.status === "success" || step.status === "completed",
                      "red-bg": step.status === "failure",
                      "gray-bg": step.status === "initiating",
                    })}
                >
                  {step.status === "success" && (
                      <CheckOutlined className="right-checked"/>
                  )}
                  {state?.progress > 5 && step.status === "initiating" && (
                      <CheckOutlined className="right-checked"/>
                  )}
                  {state?.progress === 100 && step.status === "completed" && (
                      <CheckOutlined className="right-checked"/>
                  )}

                  <span className="description-text">{step.description}</span>
                </li>
            ))}
          </ul>
        </div>
        </>)}
      </Dragger>
    </Modal>
  );
};

export default CsvUploadModal;
