import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import CommentBox from "../../components/CommentBox";
import ChatItem from "./ChatItem";

import { commentActions } from "../../comment/duck/commentReducer";

import "./TaskChat.scss";

const TaskChat = (props) => {
  const { id } = props;

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  // const prevProps = useRef();
  const messagesEndRef = useRef(null);

  const mParams = useMemo(() => ({ pathname, model_id: id }), [pathname, id]);
  const disabled = props?.disabled ?? false;

  const { list, meta, loading } = useSelector(({ comment }) => {
    const mFilter = JSON.stringify(mParams);
    const boards = comment.boards;
    const mBoard = boards[mFilter];

    return mBoard || {};
  });
  const [chats, setChats] = useState([]);

  const loadMore = useCallback(
    (offset = 0, limit = 10) => {
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        dispatch(commentActions.onGetAllRequest({ ...mParams, offset, limit }));
      }
    },
    [dispatch, list?.length, loading, mParams, meta?.totalCount]
  );

  const handleScroll = useCallback(
    ({ target }) => {
      if (Math.ceil(target.scrollTop) == 0) {
        if (!loading && list?.length < meta?.totalCount) {
          loadMore(list?.length);
        }
      }
    },
    [list?.length, loadMore, loading, meta?.totalCount]
  );

  useEffect(() => {
    if (mParams.pathname && mParams.model_id) {
      dispatch(commentActions.onGetAllRequest(mParams));
    }
    setChats([].concat(list).reverse());

    // return () => {
    //   prevProps.current = {
    //     chatLength: list?.length || 0,
    //   };
    // };
  }, [dispatch, list, mParams]);

  // useEffect(() => {
  //   const domNode = document.querySelector(".comment-list");
  //   const prevLength = prevProps.current?.chatLength;
  //   let scrollLength = !prevLength
  //     ? domNode.scrollHeight
  //     : domNode.scrollHeight / (list?.length - prevLength);

  //   domNode.scroll({
  //     top: scrollLength,
  //     behavior: "smooth",
  //   });
  // }, [chats, list?.length]);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chats, scrollToBottom]);

  return (
    <Col className="task-chat">
      <Row className="chat-title pri-box-shadow">Message</Row>
      <Row className="chat-container">
        <Col className="comment-list" onScrollCapture={handleScroll}>
          {chats?.map?.((item, i) => (
            <ChatItem key={i} id={item} />
          ))}
          <div ref={messagesEndRef} />
        </Col>
        <CommentBox
          disabled={disabled}
          modelId={id}
          onModel={props.onModel}
          filter={JSON.stringify({ model_id: id })}
        />
      </Row>
    </Col>
  );
};

export default React.memo(TaskChat);
