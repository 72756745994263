import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Popover,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LeftOutlined,
  UserAddOutlined,
  UploadOutlined,
  DownloadOutlined,
  SearchOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";
import moment from "moment-timezone";

import Loading from "../components/Loading";
import NotFound from "../components/NotFound";
// import ProjectTaskModal from "./components/ProjectTaskModal";

// import projectTaskListDetails from "../projectTaskListDetails";
import {
  disabledFutureDate,
  getAvatarColor,
  getNameInitials,
  getStringToOptions,
  showNotification,
} from "../utils/commonFunctions";
import User from "../models/User";
import constants from "../utils/constants";
import { useUserPermissions } from "../utils/customHooks";

// import "./ProjectDetails.scss";
import axios from "axios";
import AppUrl from "../config/appUrl";
import { itemActions } from "../item/duck/itemReducer";
import PlusIcon from "../assets/images/plusIcon.svg";
import KanbanView from "../assets/images/KanbanView.svg";
import ListView from "../assets/images/ListView.svg";
import Insights from "../assets/images/Insights.png";

import CalendarIcon from "../assets/images/Calendar.svg";

import MyTaskFilter from "../components/MyTaskFilter";

import ProjectTaskModal from "../project/components/ProjectTaskModal";
import SelectManagerModal from "../project/components/SelectManagerModal";
import projectTaskListDetails from "./components/projectTaskLiskDetails";
import ShareWithModal from "../project/components/ShareWithModal";
import SelectClientModal from "../project/components/SelectClientModal";
import ProjectListView from "../project/components/ProjectListView";
import ProjectInsightDetails from "../project/components/ProjectInsightDetails";
import { projectActions } from "../project/duck/projectReducer";
import ContentTaskList from "./components/ContentTaskList";
import CsvUploadModal from "./components/CsvUploadModal";
import routes from "../utils/routes";

const fieldsLabelToDisplay = [
  "Choose the industry of your project",
  "Choose the sub industry",
  "Choose language of the content",
  "Choose your preferred tone of content",
];

const {
  PROJECT_TASK_STATUS,
  PROJECT_STATUS,
  TASK_STATUS_OPTIONS,
  CONTENT_TYPE,
  FILTER_TYPE,
} = constants;
const defaultFilterType = FILTER_TYPE?.CREATED_BY?._id;
const defaultView = "kanban";

const ContentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const activeView = localStorage.getItem("projectView") || defaultView;
  const deafultStatus = activeView === defaultView ? "All" : null;

  const [showCreateTask, setShowCreateTask] = useState(false);
  const [showSelectManager, setShowSelectManager] = useState(false);
  const [showSelectClient, setShowSelectClient] = useState(false);
  const [showShareWithModal, setShowShareWithModal] = useState(false);
  const [listBoardKey, setListBoardKey] = useState(null);
  const [view, setView] = useState(activeView);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state, setState] = useState({
    status_description: deafultStatus,
    filterType: defaultFilterType,
  });
  const [filter, setFilter] = useState({ status_description: deafultStatus });
  const [fileList, setFileList] = useState([]);
  const [bulkUploadLoading, setBulkUploadLoading] = useState(false);

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);
  const mPermissions = useUserPermissions();

  const projectObj = useSelector(({ project }) => project.map[id]);
  const item = useSelector(({ item }) => item);

  const reduxFilters = useMemo(
    () => item?.filters?.[pathname],
    [item?.filters, pathname]
  );

  useEffect(() => {
    if (reduxFilters) {
      if (view === "list") {
        setListBoardKey(reduxFilters);
        let parsedData = JSON.parse(reduxFilters);
        let filterType = FILTER_TYPE?.CREATED_BY?._id;

        if (parsedData?.assigned_freelancer) {
          filterType = FILTER_TYPE?.WRITER?._id;
          parsedData.user = parsedData?.assigned_freelancer;
        } else if (parsedData?.current_assignee) {
          filterType = FILTER_TYPE?.ASSIGNED_TO?._id;
          parsedData.user = parsedData?.current_assignee;
        } else if (parsedData?.created_by) {
          parsedData.user = parsedData?.created_by;
        }
        if (parsedData?.start_date) {
          parsedData.start_date = moment(parsedData?.start_date);
          filterType = FILTER_TYPE?.CREATED_DATE?._id;
        }
        if (parsedData?.end_date) {
          parsedData.end_date = moment(parsedData?.end_date);
          filterType = FILTER_TYPE?.CREATED_DATE?._id;
        }
        const data = {
          task_name: "",
          filterType,
          ...parsedData,
        };

        setState(data);
        setFilter(data);
      } else {
        let parsedData = JSON.parse(reduxFilters);
        if (parsedData?.start_date) {
          parsedData.start_date = moment(parsedData?.start_date);
        }
        if (parsedData?.end_date) {
          parsedData.end_date = moment(parsedData?.end_date);
        }
        // const filter_status = parsedData?.status_description;
        let status_description = parsedData?.status_description;
//         if (filter_status === "completed") {
//           status_description = "completed";
//         }
//         if (filter_status === "approved") {
//           status_description = "approved";
//         }
//         if (filter_status === "incomplete") {
//           status_description = "incomplete";
// }

          const data = {
          task_name: "",
          ...parsedData,
          status_description: deafultStatus,
        };

        setState(data);
        setFilter(data);
      }
    }
  }, []);

  const projectTemplateForms = useMemo(
    () => projectObj?.template?.forms,
    [projectObj?.template?.forms]
  );

  const buttonValues = useMemo(() => {
    const { project_status } = state;
    const length = project_status?.length;
    let text = [];
    let value;

    if (length === 0 || length === Object.keys(PROJECT_STATUS)?.length) {
      text = "All Version";
      value = 2;
      // meta?.totalCount;
    } else {
      value = project_status?.reduce((acc, value) => acc + (value ?? 0), 0);
      Object.values(PROJECT_STATUS).forEach((obj) => {
        if (project_status?.includes(obj._id)) text.push(obj.name);
      });
      text = text?.join(", ");
    }

    return { text, value };
  }, [state]);
  // const assignedEditor = useMemo(
  //   () => new User().fromMap(projectObj?.assigned_editor),
  //   [projectObj?.assigned_editor]
  // );

  const assignedManager = useMemo(
    () => new User().fromMap(projectObj?.assigned_manager),
    [projectObj?.assigned_manager]
  );
  const createdBy = useMemo(
    () => new User().fromMap(projectObj?.created_by),
    [projectObj?.created_by]
  );
  const assignedEditor = useMemo(
    () => new User().fromMap(projectObj?.assigned_editor),
    [projectObj?.assigned_editor]
  );

  const onFilter = useCallback(() => {
    setFilter((prevFilter) => ({ ...prevFilter, ...state }));
  }, [state]);

  // const onDownloadAllTasks = useCallback(() => {
  //   let {
  //     filterType,
  //     user,
  //     start_date,
  //     end_date,
  //     task_name,
  //     status_description,
  //     content_type,
  //   } = filter;
  //   task_name = task_name?.trim?.();

  //   const params = {
  //     project_id: projectObj._id,
  //     project_name: projectObj.name,
  //   };

  //   if (task_name) params.task_name = task_name;
  //   if (start_date)
  //     params.start_date = moment(start_date).startOf("day").utc().format();

  //   if (end_date)
  //     params.end_date = moment(end_date).endOf("day").utc().format();

  //   if (content_type) {
  //     params.content_type = content_type;
  //   }

  //   if (filterType === FILTER_TYPE?.WRITER?._id && user)
  //     params.assigned_freelancer = user;
  //   if (filterType === FILTER_TYPE.CREATED_BY?._id && user)
  //     params.created_by = user;
  //   if (filterType === FILTER_TYPE.ASSIGNED_TO?._id && user)
  //     params.current_assignee = user;

  //   if (status_description && view === "list") {
  //     params.status_description = status_description;
  //   } else {
  //     if (status_description === "incomplete") {
  //       params.status_description = Object.values(PROJECT_TASK_STATUS).filter(
  //         (status) => status !== PROJECT_TASK_STATUS.APPROVED
  //       );
  //     } else if (status_description === "approved") {
  //       params.status_description = [PROJECT_TASK_STATUS.APPROVED];
  //     } else {
  //       params.status_description = Object.values(PROJECT_TASK_STATUS);
  //     }
  //   }

  //   axios({
  //     method: "GET",
  //     url: `${AppUrl.ITEMS}/export`,
  //     params: params,
  //     responseType: "arraybuffer",
  //   })
  //     .then((response) => {
  //       if (response.data) {
  //         let filename = response.headers["content-type"].split("=");
  //         fileDownload(response.data, filename[1]);
  //         showNotification("success", "File downloaded.");
  //       } else {
  //         showNotification("error", "Error while downloading file.");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       showNotification("error", "Something went wrong");
  //     });
  // }, [filter, projectObj, view]);

  // const onResetFilter = useCallback(() => {
  //   let status_description = "incomplete";
  //   if (view === "list") {
  //     status_description = null;
  //   }
  //   setState({ filterType: defaultFilterType, status_description });
  //   setFilter({ status_description });
  //   setListBoardKey(null);
  // }, [view]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;
      let newState = {
        ...state,
        [name]: value,
      };
      if (name === "filterType") {
        newState.user = null;
      }

      setState(newState);
    },
    [state]
  );

  useEffect(() => {
    dispatch(projectActions.onGetOneRequest({ _id: id }));
  }, [id, dispatch]);

  // const canAssignEditor = useMemo(
  //   () =>
  //     !assignedManager._id &&
  //     (loggedInUser?.role_id === constants.ROLE_IDS.SUPER_ADMIN ||
  //       loggedInUser?.role_id === constants.ROLE_IDS.ADMIN),
  //   [assignedManager, loggedInUser]
  // );
  // const canShareProject = useMemo(
  //   () =>
  //     loggedInUser?.role_id === constants.ROLE_IDS.SUPER_ADMIN ||
  //     loggedInUser?.role_id === constants.ROLE_IDS.ADMIN ||
  //     loggedInUser?.role_id === constants.ROLE_IDS.CLIENT,
  //   [loggedInUser]
  // );
  // const canAssignManager = useMemo(
  //   () =>
  //     !assignedManager._id &&
  //     loggedInUser?.role_id === constants.ROLE_IDS.SUPER_ADMIN ||
  //     loggedInUser?.role_id === constants.ROLE_IDS.ADMIN,
  //   [assignedManager, loggedInUser]
  // );

  const canEditManager = useMemo(
    () =>
      (assignedManager._id && loggedInUser?._id === assignedManager._id) ||
      loggedInUser?.role_id === constants.ROLE_IDS.SUPER_ADMIN ||
      loggedInUser?.role_id === constants.ROLE_IDS.ADMIN,
    [assignedManager, loggedInUser]
  );
  const isProjectEditable = useMemo(() => {
    let editable = true;

    if (
      projectObj?.archive ||
      projectObj?.status_description === PROJECT_STATUS.CLOSED._id
    ) {
      editable = false;
    }
    return editable;
  }, [projectObj?.archive, projectObj?.status_description]);

  // const handleCreateProjectTaskModal = useCallback((show = false) => {
  //   show = typeof show === "boolean" && show;
  //   setShowCreateTask(show);
  // }, []);

  const handleViewChange = useCallback((sView = "kanban") => {
    let status_description = "incomplete";
    if (sView === "list") {
      status_description = null;
    }
    localStorage.setItem("projectView", sView);
    setState({ filterType: defaultFilterType, status_description });
    setFilter({ status_description });
    setView(sView);
    setListBoardKey(null);
  }, []);

  const onGoBack = useCallback(() => navigate(routes.BULK_CONTENT_CREATION), [navigate]);
  // const beforeUpload = useCallback((file) => {
  //   const supportedFileType = [
  //     "application/vnd.ms-excel",
  //     "application/msexcel",
  //     "application/x-msexcel",
  //     "application/x-ms-excel",
  //     "application/x-excel",
  //     "application/x-dos_ms_excel",
  //     "application/xls",
  //     "application/x-xls",
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   ];
  //   let isAcceptable = supportedFileType.includes(file?.type);
  //   if (!isAcceptable)
  //     showNotification("error", "Please upload Excel files only");

  //   return isAcceptable || Upload.LIST_IGNORE;
  // }, []);
  // const updateFile = useCallback(
  //   ({ file }) => {
  //     setBulkUploadLoading(true);

  //     const formData = new FormData();
  //     formData.append("project_id", id);
  //     formData.append("file", file);

  //     axios({
  //       method: "post",
  //       url: AppUrl.BULK_UPLOAD,
  //       data: formData,
  //     })
  //       .then((response) => {
  //         if (
  //           response?.data?.meta?.status === 200 &&
  //           response?.data?.meta?.success === true
  //         ) {
  //           const params = {
  //             project_id: id,
  //             status_description: "initiate",
  //             offset: 0,
  //             limit: 30,
  //           };
  //           setBulkUploadLoading(false);
  //           dispatch(itemActions.onGetAllRequest(params));
  //           showNotification("success", "Successfully Uploaded file");
  //         } else {
  //           setBulkUploadLoading(false);

  //           showNotification("error", response?.data?.meta?.message);
  //         }
  //       })
  //       .catch((error) => {
  //         setBulkUploadLoading(false);
  //         showNotification("error", "Some thing went wrong");
  //       });
  //   },
  //   [dispatch, id]
  // );

  const sharedWith = useMemo(() => {
    const mUser = [];
    projectObj?.sharedWith?.forEach((user) => {
      const userObj = new User().fromMap(user);
      mUser.push(userObj);
    });
    return mUser;
  }, [projectObj?.sharedWith]);

  useEffect(() => {
    if (
      projectObj?.assigned_manager &&
      isProjectEditable &&
      !projectObj?.loading &&
      projectObj?.count?.total_items === 0
    )
      setShowCreateTask(true);
  }, [
    isProjectEditable,
    projectObj?.assigned_manager,
    projectObj?.count?.total_items,
    projectObj?.loading,
  ]);

  // check if project is fetching
  if (!projectObj?._id && projectObj?.loading) {
    return <Loading />;
  }
  // check if project exist
  else if (!projectObj?._id) {
    return <NotFound subTitle={"Sorry, this project does not exist."} />;
  }

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="content-body app-container project-details-container content-details">
      {/*  project details card start */}
      <div className="project-details">
        <Row className="mb20" align="middle" gutter={[10, 10]}>
          <Row
            style={{ position: "relative" }}
            className="fw"
            justify={"space-between"}
          >
            <div className="page-heading">
              <LeftOutlined
                style={{
                  position: "absolute",
                  left: -22,
                  top: 10,
                  fontSize: 15,
                  cursor: "pointer",
                }}
                onClick={onGoBack}
              />
               {projectObj.name}
              {/*Project Name*/}
            </div>

            <Col>
              <Tooltip title="Kanban View">
                <button
                  className={`custom-button mr10 ${
                    view === "kanban" ? "active" : ""
                  }`}
                  onClick={() => handleViewChange("kanban")}
                >
                  <img width={25} src={KanbanView} alt="KanbanView icon" />
                </button>
              </Tooltip>
              <Tooltip title="List View">
                <button
                  className={`custom-button mr10 ${
                    view === "list" ? "active" : ""
                  }`}
                  onClick={() => handleViewChange("list")}
                >
                  <img width={25} src={ListView} alt="ListView icon" />
                </button>
              </Tooltip>
              <Tooltip title="Project Insights">
                <button
                  className={`custom-button ${
                    view === "insights" ? "active" : ""
                  }`}
                  onClick={() => setView("insights")}
                >
                  <img width={25} src={Insights} alt="insight graph icon" />
                </button>
              </Tooltip>
            </Col>
          </Row>
          {/*  assign manager & editor start */}
          <Col span={24}>
            <Row justify="space-between">
              {assignedManager._id ? (
                <Row className="pointer" align="middle">
                  <Avatar.Group>
                    {assignedManager._id && (
                      <Tooltip
                        title={
                          assignedManager.fullName
                            ? assignedManager.fullName
                            : assignedManager.email
                        }
                        placement="bottom"
                      >
                        <Avatar
                          style={{
                            backgroundColor: getAvatarColor(
                              assignedManager._id
                            ),
                          }}
                          // onClick={() => setShowSelectManager(true)}
                        >
                          {/* {getNameInitials(
                            assignedManager.fullName
                              ? assignedManager.fullName
                              : assignedManager.email
                          )} */}
                          SG
                        </Avatar>
                      </Tooltip>
                    )}

                    {assignedEditor._id && (
                      <Tooltip
                        title={
                          assignedEditor.fullName
                            ? assignedEditor.fullName
                            : assignedEditor.email
                        }
                        placement="bottom"
                      >
                        <Avatar
                          style={{
                            backgroundColor: getAvatarColor(assignedEditor._id),
                          }}
                          // onClick={() => setShowSelectManager(true)}
                        >
                          {/* {getNameInitials(
                            assignedEditor.fullName
                              ? assignedEditor.fullName
                              : assignedEditor.email
                          )} */}
                          MA
                        </Avatar>
                      </Tooltip>
                    )}
                  </Avatar.Group>
                </Row>
              ) : (
                canEditManager &&
                isProjectEditable && (
                  <Tooltip title="Assign Manager" placement="bottom">
                    <Avatar
                      className="btn-select-manager pointer"
                      icon={<UserAddOutlined />}
                      onClick={() => setShowSelectManager(true)}
                    />
                  </Tooltip>
                )
              )}
            </Row>
          </Col>
          {/* assign manager & editor end */}

          {/*  created date start */}
          <Col className="mt5" span={24}>
            <span className="sub-header">
              {/* <img
                width={11}
                className="mr5"
                src={CalendarIcon}
                alt="calendar icon"
              /> */}
              <CalendarOutlined className="mr5" />
              Created on{" "}
              {moment(projectObj?.created_at).isValid()
                ? moment(projectObj?.created_at).format("MMM Do, YYYY")
                : "-"}{" "}
              By Murari Admin
              {/* {createdBy.fullName ? createdBy.fullName : createdBy.email} */}
            </span>
          </Col>
          {/*  created date end */}
        </Row>

        <Row align="middle" gutter={[10, 10]}>
          <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={20}>
            {/*  sharw with others start */}
            <Row align="middle">
              {
                <>
                  <Avatar.Group
                    className="mr5"
                    maxCount={3}
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                    }}
                  >
                    {sharedWith?.map((obj) => (
                      <Tooltip
                        key={obj?._id}
                        title={obj?.fullName ? obj?.fullName : obj?.email}
                      >
                        <Avatar
                          className=" pointer"
                          style={{
                            backgroundColor: getAvatarColor(obj._id),
                          }}
                          // onClick={() => setShowShareWithModal(true)}
                        >
                          {getNameInitials(
                            obj?.fullName ? obj?.fullName : obj?.email
                          )}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </Avatar.Group>
                  {isProjectEditable && (
                    <Avatar
                      className=" pointer"
                      icon={<UserAddOutlined />}
                      // onClick={() => setShowShareWithModal(true)}
                    />
                  )}
                </>
              }
            </Row>
            {/*  sharw with others end */}

            {/* project details section start */}
            <Col className="mt5" span={24}>
              {/* Industry Name | Sub-Industry Name | Language | Content Tone */}
              <p
                style={{
                  // maxWidth: 600,
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="sub-header">
                  {projectTemplateForms?.map?.((form, i) =>
                    form?.fields?.map?.((field, j) =>
                      fieldsLabelToDisplay?.includes(field.label)
                        ? `${field.value} ${
                            field.label !== fieldsLabelToDisplay.at(-1)
                              ? "| "
                              : ""
                          }`
                        : ""
                    )
                  )}
                </span>
              </p>
            </Col>
            {/* project details section  end */}
          </Col>
          {isProjectEditable &&
            mPermissions?.task?.create &&
            assignedManager._id && (
              <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={4}>
                <Row style={{ flexDirection: "column" }}>
                  <Row wrap={false}>
                    <Button
                      className="new_button"
                      type="primary"
                      size="large"
                      icon={<DownloadOutlined className="f20" />}
                      onClick={() =>
                        (window.location.href = "/Bulk Sample File.xlsx")
                      }
                    />
                    <Button
                      onClick={openModal}
                      className="new_button ml15"
                      type="primary-outline"
                      size="large"
                      icon={<UploadOutlined className="f20" />}
                      // loading={bulkUploadLoading}
                      // disabled={bulkUploadLoading}
                    >
                      Bulk Upload
                    </Button>

                    {/* <Upload
                    className="ml15"
                    accept={".xlsx, .xls,.xlsx"}
                    listType="picture"
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    customRequest={updateFile}
                    disabled={bulkUploadLoading}
                    ></Upload> */}
                  </Row>
                  <span className="sub-header mt5">
                    *Download Sample File And Upload here
                  </span>
                </Row>
              </Col>
            )}
        </Row>
      </div>
      {/*  project details card end */}
      {/*  project filter row start */}

      {view !== "insights" && (
        <Row className="fw mt20" gutter={[10, 10]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <Input
              className="new_search fw"
              size="large"
              placeholder="Task Name"
              prefix={<SearchOutlined />}
              value={state?.task_name}
              // onChange={handleChange("task_name")}
            />
          </Col>
          {view === "kanban" && (
            <>
              <Col xs={24} sm={8} md={8} lg={5} xl={4} xxl={3}>
                <DatePicker
                  size="large"
                  format={"DD MMM, YYYY"}
                  className="transparent_picker fw"
                  onChange={handleChange("start_date")}
                  suffixIcon={
                    <img width={15} src={CalendarIcon} alt="calendar icon" />
                  }
                  disabledDate={disabledFutureDate}
                  placeholder="Start Date"
                  value={state?.start_date}
                  inputReadOnly={true}
                />
              </Col>
              <Col xs={24} sm={8} md={8} lg={5} xl={4} xxl={3}>
                <DatePicker
                  size="large"
                  format={"DD MMM, YYYY"}
                  className="transparent_picker fw"
                  onChange={handleChange("end_date")}
                  suffixIcon={
                    <img width={15} src={CalendarIcon} alt="calendar icon" />
                  }
                  disabledDate={disabledFutureDate}
                  placeholder="End Date"
                  value={state?.end_date}
                  inputReadOnly={true}
                />
              </Col>
            </>
          )}
          {view === "list" && (
            <>
              <Col xs={24} sm={12} md={12} lg={6} xl={4} xxl={3}>
                <Select
                  className="new_select fw"
                  size="large"
                  placeholder="Content Type"
                  dropdownMatchSelectWidth={false}
                  value={state?.content_type}
                  clearIcon={
                    <CloseCircleOutlined
                      style={{
                        color: "#fff",
                        background: "#000",
                        fontSize: 13,
                      }}
                    />
                  }
                  onChange={handleChange("content_type")}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  showSearch
                >
                  {getStringToOptions(CONTENT_TYPE).map((option, i) => (
                    <Select.Option key={i} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={5} xxl={4}>
                <MyTaskFilter
                  handleChange={handleChange}
                  state={{ project_id: id, ...state }}
                />
              </Col>
            </>
          )}

          <Col
            xs={24}
            sm={8}
            md={8}
            lg={6}
            xl={view === "kanban" ? 4 : 3}
            xxl={view === "kanban" ? 3 : 2}
          >
            <Select
              className="new_select fw"
              size="large"
              placeholder="Status"
              dropdownMatchSelectWidth={false}
              value={state?.status_description}
              onChange={handleChange("status_description")}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {view === "kanban" ? (
                <>
                  <Select.Option value={""}>All</Select.Option>
                  <Select.Option value={"incomplete"}>Incomplete</Select.Option>
                  <Select.Option value={"completed"}>
                    Completed
                  </Select.Option>
                  <Select.Option value={"approved"}>
                    Accepted
                  </Select.Option>
                </>
              ) : (
                Object.values(TASK_STATUS_OPTIONS).map((status) => (
                  <Select.Option key={status._id} value={status._id}>
                    {status.name}
                  </Select.Option>
                ))
              )}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={3} xxl={2}>
            <Input.Group style={{ width: "auto", marginRight: 10 }} compact>
              <Popover
                getPopupContainer={(triggerNode) => triggerNode}
                trigger="hover"
                showArrow={false}
                overlayStyle={{
                  padding: 0,
                  width: 160,
                }}
                content={
                  <Checkbox.Group
                    // value="VERSION"
                    onChange={handleChange("project_status")}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: 112,
                      paddingBottom: 10,
                    }}
                  >
                    {Object.values(PROJECT_STATUS).map((obj) => (
                      <Checkbox
                        style={{
                          margin: 0,
                          width: "max-content",
                        }}
                        key={obj._id}
                        value={obj._id}
                      >
                        <span className="project-counter pri-box-shadow">
                          {/* {meta?.[obj._id] ?? 0}1 */}1
                        </span>
                        &nbsp; {obj?.name || "All Projects"}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                }
                placement="bottomLeft"
              >
                <Button className="new_button" type="primary" size="large">
                  <Row align="middle" wrap={false}>
                    <span className="project-counter project-counter-btn">
                      {/* {buttonValues?.value || 0} */}0
                    </span>
                    &nbsp;
                    {buttonValues?.text?.toString() || "All Version"}
                  </Row>
                </Button>
              </Popover>
            </Input.Group>
          </Col>
          <Col
            xs={24}
            sm={view === "kanban" ? 12 : 8}
            md={8}
            lg={4}
            xl={3}
            xxl={2}
          >
            <Button
              type="primary"
              className="new_button fw"
              size="large"
              onClick={onFilter}
            >
              Search
            </Button>
          </Col>
          <Col
            xs={24}
            sm={view === "kanban" ? 12 : 8}
            md={8}
            lg={4}
            xl={3}
            xxl={2}
          >
            <Button
              className="new_button fw"
              size="large"
              type="primary-outline"
              // onClick={onResetFilter}
            >
              Reset
            </Button>
          </Col>

          {isProjectEditable &&
            mPermissions?.task?.create &&
            assignedManager._id &&
            view !== "insights" && (
              <></>
              // <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
              //   <Button
              //     className="new_button fw"
              //     type="primary"
              //     icon={<img src={PlusIcon} alt="plus icon" className="mr10" />}
              //     // onClick={() => handleCreateProjectTaskModal(true)}
              //   >
              //     Add New Task
              //   </Button>
              // </Col>
            )}
        </Row>
      )}

      {/*  project filter row end */}
      <hr className="line pri-box-shadow" />
      {/* project task list start */}

      {view === "kanban" && (
        <Col className="project-task-lists-container">
          {projectTaskListDetails.map((task, i) => (
            <ContentTaskList
              key={i}
              listName={task.listName}
              taskStatus={task.taskStatus}
              subHeading={task.subHeading}
              projectId={id}
              filter={filter}
              isProjectEditable={isProjectEditable}
              handleModal={setShowSelectClient}
            />
          ))}
        </Col>
      )}
      {view === "list" && (
        <ProjectListView
          projectId={id}
          filter={filter}
          listBoardKey={listBoardKey}
          setListBoardKey={setListBoardKey}
        />
      )}
      {view === "insights" && <ProjectInsightDetails projectId={id} />}

      {/* project task list end */}
      {/* create project task/assignment start */}
      {/*<ProjectTaskModal*/}
      {/*  visible={showCreateTask}*/}
      {/*  projectId={id}*/}
      {/*  handleModal={*/}
      {/*    ""*/}
      {/*    // handleCreateProjectTaskModal*/}
      {/*  }*/}
      {/*/>*/}
      {/* create project task/assignment end */}
      {/* select manager start */}
      {canEditManager && (
        <>
          <SelectManagerModal
            visible={showSelectManager}
            projectId={id}
            handleModal={() => setShowSelectManager(false)}
            isProjectEditable={isProjectEditable}
          />
          <SelectClientModal
            visible={showSelectClient}
            projectId={id}
            handleModal={() => setShowSelectClient(false)}
            isProjectEditable={isProjectEditable}
          />
        </>
      )}
      {/* select manager end */}
      {/* Share with other start */}
      {
        <ShareWithModal
          visible={showShareWithModal}
          sharedWith={sharedWith}
          projectId={id}
          handleModal={() => setShowShareWithModal(false)}
          isProjectEditable={isProjectEditable}
        />
      }
      {/* Share with other end */}
      <CsvUploadModal activeProject={projectObj} isVisible={isModalVisible} onClose={closeModal} />
    </div>
  );
};

export default React.memo(ContentDetails);
