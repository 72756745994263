import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Avatar, Button, Col, Input, Mentions, Row, Upload, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// import CommentBox from "../../components/CommentBox";
// import ChatItem from "./ChatItem";

import { commentActions } from "../../comment/duck/commentReducer";

// import "./TaskChat.scss";
import ChatItem from "./ChatItem";
import CommentBox from "../../components/CommentBox";
import axios from "axios";
import AppUrl from "../../config/appUrl";
import {
  getAvatarColor,
  getNameInitials,
  hasApiCallError,
  showNotification,
} from "../../utils/commonFunctions";
import OpenAiChatItem from "../../openAi/components/OpenAiChatItem";
import paperClipSVG from "../../assets/images/PaperClip.svg";
import { SendOutlined } from "@ant-design/icons";
import FileItem from "../../task/components/FileItem";

const ChatBox = (props) => {
  const messageRefs = useRef([]);
  const [state, setState] = useState({
    message: "",
  });
  const [messages, setMessages] = useState([]);
  const onSend = useCallback(() => {
    const message = state.message.trim();
    console.log("----------onsend");

    if (!message) return;

    const newMessages = messages;
    newMessages.push({
      role: "user",
      content: message,
    });
    const payload = { messages: [...newMessages] };

    newMessages.push({
      role: "assistant",
      content: "typing...",
      loading: true,
    });

    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: true }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );

    axios({
      method: "POST",
      url: `${AppUrl.OPENAI}/chat`,
      data: payload,
    })
      .then((response) => {
        const resData = response.data;

        if (hasApiCallError(resData?.meta)) {
          setMessages((prevMessages) => {
            const lastMesssage = prevMessages.pop();
            console.log("remove message: ", lastMesssage);

            return [...prevMessages];
          });
        } else {
          const newMessage = resData?.data?.content;
          let content = newMessage?.content;
          while (content.startsWith("\n")) {
            content = content.replace("\n", "");
          }
          newMessage.content = content;

          setTimeout(
            () =>
              messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                behavior: "smooth",
              }),
            100
          );

          setMessages((prevMessages) => {
            prevMessages.pop();

            return [...prevMessages, newMessage];
          });
        }

        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", "Something went wrong");
        setMessages((prevMessages) => {
          const lastMesssage = prevMessages.pop();
          console.log("remove message: ", lastMesssage);

          return [...prevMessages];
        });
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, [messages, state, messageRefs]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
  );

  console.log("------message", messages);
  return (
    <Col className="task-chat">
      <Row className="chat-title pri-box-shadow">Writely Bot </Row>
      <Row className="chat-container">
        <Col
          className="comment-list"
          // onScrollCapture={handleScroll}
        >
          {messages?.map?.((message, i) => (
            <div key={i} ref={(mRef) => (messageRefs.current[i] = mRef)}>
              <ChatItem key={i} message={message} />
            </div>
          ))}
        </Col>
        <Col className="comment-box">
          <Row className="comment-container" align="middle">
            <Col className="input-container" align="stretch">
              <Input
                className="chat-input custom-mention"
                value={state.message}
                placeholder="Type your question or topic of interest here"
                onChange={handleChange("message")}
                autoSize={{
                  minRows: 1,
                  maxRows: 3,
                }}
                style={{
                  position: "relative",
                  top: "50%",
                }}
                onPressEnter={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    onSend();
                  }
                }}
                disabled={state.loading}
                placement="top"
              />
            </Col>
            <Row className="action-btn-container mr10" align="middle">
              {/*<Col className="attachment-btn-container ">*/}
              {/*<Upload*/}
              {/*    className="mr5"*/}
              {/*    // accept={".jpeg, .jpg, .png, .pdf, .docx, .doc"}*/}
              {/*    accept={"*"}*/}
              {/*    multiple={false}*/}
              {/*    listType={"text"}*/}
              {/*    showUploadList={false}*/}
              {/*    fileList={fileList}*/}
              {/*    // beforeUpload={beforeUpload}*/}
              {/*    customRequest={({ file }) => setFileList([file])}*/}
              {/*    disabled={comment?.loading || props?.disabled}*/}
              {/*>*/}
              {/*  <img*/}
              {/*      src={paperClipSVG}*/}
              {/*      alt="upload icon"*/}
              {/*      style={{ cursor: "pointer" }}*/}
              {/*  />*/}
              {/*</Upload>*/}
              {/*</Col>*/}

              <Button
                type="text"
                size="large"
                icon={<SendOutlined style={{ fontSize: 23 }} />}
                onClick={onSend}
                disabled={!state.message.trim()}
                // loading={comment?.loading}
              />
            </Row>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(ChatBox);
