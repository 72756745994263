import React, { useCallback, useMemo } from "react";
import {
  Row,
  Avatar,
  Dropdown,
  Menu,
  Progress,
  Tooltip,
  Space,
  Badge,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

import routes from "../../utils/routes";
import NameWithAvatar from "../../components/NameWithAvatar";
// import "./ProjectRowItem.scss";
import User from "../../models/User";
import constants from "../../utils/constants";
// import { projectActions } from "../duck/projectReducer";

import CalendarIcon from "../../assets/images/Calendar.svg";
import { projectStatusTransformer } from "../../components/StatusTransformer";
import { abbreviateNumber } from "../../utils/commonFunctions";
const { PROJECT_STATUS } = constants;

const ContentRowItem = (props) => {
  const { id, boardKey } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mProject = useSelector(({ project }) => project.map[id]);

  const assignedManager = useMemo(
    () => new User().fromMap(mProject?.assigned_manager),
    [mProject?.assigned_manager]
  );
  const createdBy = useMemo(
    () => new User().fromMap(mProject?.created_by),
    [mProject?.created_by]
  );

  const onProjectDetails = useCallback(
    () => navigate(routes.CONTENT_DETAIL.replace(":id", id)),
    [navigate, id]
  );
  //   const handleArchive = useCallback(() => {
  //     const archiveStatus = mProject?.archive;
  //     const payload = {
  //       data: {
  //         _id: mProject._id,
  //         archive: !archiveStatus,
  //       },
  //       filter: boardKey,
  //     };
  //     dispatch(projectActions.archiveRequest(payload));
  //   }, [boardKey, dispatch, mProject._id, mProject?.archive]);

  //   const handleStatus = useCallback(() => {
  //     const payload = {
  //       _id: mProject._id,
  //     };
  //     if (mProject?.status_description === PROJECT_STATUS.CLOSED._id) {
  //       dispatch(projectActions.reopenRequest(payload));
  //     } else {
  //       payload.status_description = PROJECT_STATUS.CLOSED._id;
  //       dispatch(projectActions.onUpdateOneRequest(payload));
  //     }
  //   }, [dispatch, mProject._id, mProject?.status_description]);

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label:
          mProject?.status_description === PROJECT_STATUS.CLOSED._id
            ? "Reopen"
            : "Close",
        onClick: "",
        // handleStatus,
      },
      {
        key: 2,
        label: mProject?.archive ? "Un Archive" : "Archive",
        onClick: "",
        // handleArchive,
      },
    ],
    [
      //   handleArchive,
      //   handleStatus,
      mProject?.archive,
      mProject?.status_description,
    ]
  );
  const onClickMenu = useCallback(
    (e) => {
      e.domEvent.stopPropagation();
      const mItem = menuItems.find((item) => item.key === e.key);
      mItem?.onClick?.();
    },
    [menuItems]
  );

  const {
    total_word,
    total_items,
    approved_item,
    initiate_items,
    completed_items,
    in_review_items,
    in_progress_items,
  } = mProject?.count || {};

  const approvedPercentage = useMemo(
    () => (approved_item / total_items) * 100 || 0,
    [approved_item, total_items]
  );

  const { completedAndAcceptedPercentage, inProgressPercentage } =
    useMemo(() => {
      const completedAndAcceptedPercentage =
        (approved_item / total_items) * 100;

      const inProgressPercentage =
        ((in_progress_items + in_review_items + completed_items) /
          total_items) *
        100;

      return {
        completedAndAcceptedPercentage,
        inProgressPercentage,
      };
    }, [
      approved_item,
      completed_items,
      in_progress_items,
      in_review_items,
      total_items,
    ]);

  if (!mProject?._id) {
    return <></>;
  }

  return (
    <tr className="pointer" onClick={onProjectDetails}>
      <td>
        <Tooltip title={mProject?.name?.length >= 27 ? mProject?.name : ""}>
          <p
            style={{
              maxWidth: "200px",
              display: "inline-block",
            }}
            className="low-opacity overflow-ellipse"
          >
            {mProject?.name}
          </p>
          <span className="low-opacity">
            <img
              width={11}
              className="mr5"
              src={CalendarIcon}
              alt="calendar icon"
            />
            Created on{" "}
            {moment(mProject?.created_at).isValid()
              ? moment(mProject?.created_at).format("MMM Do, YYYY")
              : "-"}
          </span>
        </Tooltip>
      </td>
      <td style={{ width: "240px" }}>
        <Tooltip
          placement="bottom"
          getPopupContainer={(triggerNode) => triggerNode}
          overlayStyle={{
            padding: 0,
          }}
          title={
            <Space direction="vertical">
              <Badge
                color="#1DC96C"
                text={
                  <span style={{ color: "#fff", fontSize: "13px" }}>{`${
                    approved_item ?? 0
                  } Approved (Completed & Accepted)`}</span>
                }
              />
              <Badge
                color="#F3AF4B"
                text={
                  <span style={{ color: "#fff", fontSize: "13px" }}>{`${
                    in_review_items + completed_items + in_progress_items ?? 0
                  } In-progress (with Client, Editor, Freelancer)`}</span>
                }
              />
              <Badge
                color="#00C5E5"
                text={
                  <span style={{ color: "#fff", fontSize: "13px" }}>{`${
                    initiate_items ?? 0
                  } To-do (with Manager)`}</span>
                }
              />
            </Space>
          }
        >
          <Row justify="space-between">
            <span style={{ fontSize: 14 }} className="low-opacity">
              Approved: {approved_item}/{total_items} items
            </span>
            <span style={{ fontSize: 14 }} className="low-opacity">
              {approvedPercentage.toFixed(0)}%
            </span>
          </Row>
          <Progress
            strokeColor="#F3AF4B" // orange
            showInfo={false}
            percent={inProgressPercentage + completedAndAcceptedPercentage}
            success={{
              percent: completedAndAcceptedPercentage,
              strokeColor: "#1DC96C", // green
            }}
            strokeWidth={6}
            trailColor={total_items ? "#00C5E5" : "#C1C1C1"} // blue
          />
        </Tooltip>
      </td>

      <td>
        <p
          style={{
            maxWidth: "110px",
          }}
          className="low-opacity inline-block overflow-ellipse"
        >
          {abbreviateNumber(Number(total_word || 0), 1)}
        </p>
      </td>
      <td>
        <p
          style={{
            maxWidth: "200px",
          }}
          className=" overflow-ellipse inline-block"
        >
          <NameWithAvatar user={createdBy} showFullName />
        </p>
      </td>
      <td>
        <p
          style={{
            maxWidth: "200px",
          }}
          className="inline-block overflow-ellipse"
        >
          <NameWithAvatar user={assignedManager} showFullName />
        </p>
      </td>
      <td>
        <Row wrap={false}>
          {projectStatusTransformer(mProject?.status_description)}

          {/*<Dropdown*/}
          {/*  overlay={<Menu items={menuItems} onClick={(e) => onClickMenu(e)} />}*/}
          {/*  placement="bottom"*/}
          {/*  trigger={["click"]}*/}
          {/*>*/}
          {/*  <MoreOutlined*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      e.stopPropagation();*/}
          {/*    }}*/}
          {/*    style={{ fontSize: 20, marginTop: 5 }}*/}
          {/*  />*/}
          {/*</Dropdown>*/}
        </Row>
      </td>
    </tr>
  );
};

export default React.memo(ContentRowItem);
