import { all } from "redux-saga/effects";

import loggedInUserSaga from "../loggedInUser/duck/loggedInUserSaga";
import userSage from "../users/duck/userSage";
import roleSage from "../role/duck/roleSage";
import commentSage from "../comment/duck/commentSage";
import taskSage from "../task/duck/taskSage";
import projectSaga from "../project/duck/projectSaga";
import itemSage from "../item/duck/itemSage";
import templateSaga from "../template/duck/templateSaga";
import dashboardSaga from "../dashboard/duck/dashboardSaga";
import assessmentSaga from "../assessment/duck/assessmentSaga";
import assessmentTestSaga from "../assessmentTest/duck/assessmentTestSaga";
import notificationSaga from "../notifications/duck/notificationSaga";
import openAiSaga from "../openAi/duck/openAiSage";
import progressSaga from "../bulkContentCreation/duck/progressSaga";

function* rootSaga() {
  yield all([
    loggedInUserSaga(),
    userSage(),
    roleSage(),
    assessmentSaga(),
    assessmentTestSaga(),
    taskSage(),
    commentSage(),
    projectSaga(),
    itemSage(),
    dashboardSaga(),
    templateSaga(),
    notificationSaga(),
    openAiSaga(),
      progressSaga()
  ]);
}

export default rootSaga;
