import constants from "../../utils/constants";

const { PROJECT_TASK_STATUS } = constants;
const projectTaskListDetails = [
    {
        listName: "To-Do List",
        subHeading: "With Manager",
        taskStatus: PROJECT_TASK_STATUS.INITIATE,
    },
    {
        listName: "In Review",
        subHeading: "With Writer / Freelancer",

        taskStatus: PROJECT_TASK_STATUS.IN_REVIEW,
    },
    {
        listName: "Completed",
        subHeading: "With Editor",

        taskStatus: PROJECT_TASK_STATUS.COMPLETED,
    },
    {
        listName: "Accepted",
        subHeading: "Now you Can Accept Your Task",
        taskStatus: PROJECT_TASK_STATUS.APPROVED,
    },
];

export default projectTaskListDetails;
