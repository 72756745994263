import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Button, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate, useParams, useLocation} from "react-router-dom";

import Loading from "../components/Loading";
import { projectActions } from "./duck/projectReducer";
import { useQuery } from "../utils/customHooks";
import {
  getOptionsToString,
  getStringToOptions,
} from "../utils/commonFunctions";
import routes from "../utils/routes";
import "./CreateProject.scss";
import InputField from "../components/InputField";

const CreateProject = () => {
  const prevProps = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState(0);

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  const query = useQuery();
  const draftProjectId = useMemo(() => query.get("draft_id"), [query]);

  const projectMap = useSelector(({ project }) => project.map);
  const mProject = useMemo(
    () => projectMap[draftProjectId],
    [projectMap, draftProjectId]
  );
  const mTemplateForms = mProject?.template?.forms;

  useEffect(() => {
    if (!mProject) {
      const params = { _id: draftProjectId };
      dispatch(projectActions.onGetOneRequest(params));
    } else if (
      (prevProps.current?.loading && !mProject?.loading) ||
      (typeof prevProps.current === "undefined" && mProject)
    ) {
      if (mProject.draft) {
        const mTemplateForms = mProject?.template?.forms || [];
        let newState = {};

        mTemplateForms.forEach((form, step) => {
          const fields = form.fields;
          fields.forEach((field) => {
            if (field.value) {
              if (field.multiselct) {
                newState[field.fid] = getStringToOptions(field.value);
              } else {
                newState[field.fid] = field.value;
              }
            }
          });
        });
        setState(newState);

        if (typeof prevProps.current !== "undefined") {
          const newCurrentStep = (prevProps.current.currentStep ?? -1) + 1;
          setCurrentStep(newCurrentStep);
        }
      } else {
        if(location?.search.includes("bulk")){
          navigate(`${routes.CONTENT_DETAIL.replace(":id", draftProjectId)}`, {
            replace: true,
          });
        } else {
          navigate(`${routes.PROJECT}/${draftProjectId}`, {
            replace: true,
          });
        }

      }
    }

    return () => {
      prevProps.current = {
        loading: mProject?.loading,
        currentStep: currentStep,
      };
    };
  }, [navigate, dispatch, draftProjectId, mProject, currentStep]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    const currentForm = mTemplateForms[currentStep];
    const fields = currentForm.fields;

    const error = {};
    fields.forEach((field) => {
      if (field.mandatory && !state[field.fid]) {
        error[field.fid] = field.validation;
      }
    });

    setError(error);

    return !Object.keys(error).length;
  }, [state, currentStep, mTemplateForms]);

  const onNext = useCallback(() => {
    if (hasError()) {
      const payload = {
        _id: draftProjectId,
        data: [],
      };
      const mFields = mTemplateForms?.[currentStep]?.fields;
      mFields.forEach((field) => {
        let value = field.multiselct
          ? getOptionsToString(state[field?.fid])
          : state[field?.fid];
        value = value?.trim?.();

        payload.data.push({
          section_id: field?.section_id,
          fid: field?.fid,
          value: value,
        });
      });

      dispatch(projectActions.onUpdateSectionFieldsRequest(payload));
    }
  }, [hasError, state, draftProjectId, mTemplateForms, currentStep, dispatch]);

  const onPrevious = useCallback(
    () =>
      setCurrentStep((prevState) => {
        if (prevState - 1 >= 0) {
          return prevState - 1;
        } else {
          navigate(-1);
          return 0;
        }
      }),
    [navigate]
  );

  if (!mProject?._id && mProject?.loading) {
    return <Loading />;
  }

  return (
    <div className="content-body app-container create-project-layout">
      <Col>
        <ul className="step-nav">
          {mTemplateForms?.map?.((form, i) => (
            <li
              key={i}
              className={currentStep === i ? "active pri-box-shadow" : ""}
            >
              {form?.header?.label}
            </li>
          ))}
        </ul>
      </Col>
      <div className="project-form-container mt30">
        <div className="header pri-box-shadow">Create A Project</div>
        <div className="body">
          {mTemplateForms?.[currentStep]?.fields?.map((field, i) => {
            field = Object.assign({}, field, {
              value: state?.[field?.fid],
              error: error?.[field?.fid],
            });

            return (
              <InputField
                key={i}
                {...field}
                disabled={field?.read_only || mProject?.loading}
                onChange={handleChange(field.fid)}
              />
            );
          })}
        </div>
        <div className="footer">
          <Button
            className="new_button"
            type="primary-outline"
            size="large"
            onClick={onPrevious}
          >
            Previous
          </Button>

          <Button
            className="new_button"
            type="primary"
            size="large"
            loading={mProject?.loading}
            onClick={onNext}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
